<template>
  <div>
    <!-- IP地址 请输入IP地址，多个IP换行分隔-->
    <el-row style="margin-top:25px;">
      <el-form
        :model="deviceData"
        ref="deviceData"
        :rules="rules"
        label-width="160px"
        class="demo-ruleForm"
      >
        <el-form-item label="IP地址：" prop="addrList">
          <el-input
            style="width: 400px"
            size="small"
            v-model="deviceData.addrList"
            type="textarea"
            :rows="8"
            placeholder="输入多个IP地址需换行"
          ></el-input>
        </el-form-item>
        <el-form-item>
          <el-button
            size="small"
            @click="submitForm('deviceData')"
            type="primary"
            >保存</el-button
          >
        </el-form-item>
      </el-form>
    </el-row>
  </div>
</template>
<script>
export default {
  data() {
    function validateIP(rule, value, callback) {
      var IPRegex =
        /^(25[0-5]|2[0-4]\d|1\d{2}|[1-9]\d|[1-9])\.((([0-9]|([1-9]\d)|(1\d\d)|(2([0-4]\d|5[0-5])))))\.((([0-9]|([1-9]\d)|(1\d\d)|(2([0-4]\d|5[0-5])))))\.((([0-9]|([1-9]\d)|(1\d\d)|(2([0-4]\d|5[0-5])))))$/;
      let valdata = value.split("\n");
      valdata.forEach((item) => {
        if (!IPRegex.test(item)) {
          callback(new Error("请输入正确的IP地址,如：192.168.0.1"));
          return;
        }
      });
      callback();
    }
    return {
      deviceData: {
        addrList: "", //IP地址
        type: "0", //自定义IP
        status: "1", //1-锁定
        lockTime: "2020-05-12 16:36:22", //锁定时间
      },
      rules: {
        addrList: [
          {
            required: true,
            message: this.$t("SecurityMonitor.DangerIPPage.IPVoid"),
            trigger: "blur",
          },
          { validator: validateIP, trigger: "blur" },
        ],
      },
    };
  },
  created() {},
  mounted() {},
  methods: {
    // 保存
    submitForm(ruleForm) {
      this.$refs[ruleForm].validate((valid) => {
        if (valid) {
          this.saveData();
        } else {
          return false;
        }
      });
    },
    async saveData() {
      console.log(this.deviceData.addrList, "IP地址");
      var arr = this.deviceData.addrList.split("\n");
      console.log(arr, "IP地址arr");
      // // 删除空字符串
      // for (var i = arr.length - 1; i >= 0; i--) {
      //   if (arr[i] == "") {
      //     arr.splice(i, 1);
      //   }else{
      //     if (!/^[0-9a-zA-Z_]{1,}$/.test(arr[i])){
      //     }
      //   }
      // }

      const parmas = {
        addrList: arr, //IP地址
        type: this.deviceData.type, //自定义IP
        status: this.deviceData.status, //1-锁定
      };
      console.log(parmas, "保存参数");
      // if (arr.length > 200) {
      //   this.$message({
      //     message: "最多支持200个UDID",
      //     type: "error",
      //     offset: 100,
      //   });
      // } else {
      const res = await this.$axios.post(
        "/httpServe/dangerIp/save",
        parmas,
        true
      );

      if (res.data == 1) {
        this.$message({
          message: this.$t("public.SuccessfullySaved"),
          type: "success",
          offset: 100,
        });
        this.$emit("addDangerIP", 0);
      }
      // }
    },
  },
};
</script>

<style lang='scss' scoped>
</style>