import { render, staticRenderFns } from "./dangerIpCom.vue?vue&type=template&id=f36d4e46&scoped=true&"
import script from "./dangerIpCom.vue?vue&type=script&lang=js&"
export * from "./dangerIpCom.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "f36d4e46",
  null
  
)

export default component.exports