<template>
  <div>
    <el-row class="common-wrapper" v-if="isHidden == 1">
      <el-row :gutter="4" class="elRow" style="display: flex">
        <el-col :span="3">
          <el-date-picker
            :clearable="false"
            style="width: 100%"
            size="small"
            v-model="formInline.optionDate1"
            type="date"
            :placeholder="$t('SecurityMonitor.DangerIPPage.dataPlaceholder')"
            value-format="yyyy-MM-dd"
            :picker-options="pickerOptions0"
            @change="changeDate"
          >
          </el-date-picker>
        </el-col>
        <el-col :span="0.5">
          <span style="line-height: 30px; color: #dcdfe6">~</span>
        </el-col>
        <el-col :span="3">
          <el-date-picker
            :clearable="false"
            style="width: 100%"
            size="small"
            v-model="formInline.optionDate2"
            type="date"
            :placeholder="$t('SecurityMonitor.DangerIPPage.dataPlaceholder')"
            value-format="yyyy-MM-dd"
            :picker-options="pickerOptions1"
            @change="changeDate"
          >
          </el-date-picker>
        </el-col>
        <el-col :span="3">
          <el-select
            style="width: 100%"
            v-model="formInline.value"
            :placeholder="$t('SecurityMonitor.DangerIPPage.typePlaceholder')"
            size="small"
            @change="changeSele"
          >
            <el-option
              v-for="item in typeData"
              :key="item.value"
              :label="$t(`SecurityMonitor.DangerIPPage.typeData.${item.label}`)"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </el-col>
        <el-col :span="3">
          <el-input
            style="width: 100%"
            size="small"
            v-model="formInline.keywords"
            :placeholder="$t('SecurityMonitor.DangerIPPage.inputPlaceholder')"
            @keyup.enter.native="onSubmit('ser')"
          ></el-input>
        </el-col>
        <!-- <select-input :options="typeData" @selectSearchChange="selectSearchChange" @searchList="onSubmit"></select-input> -->
        <el-col :span="0.7">
          <el-button size="small" type="primary" @click="onSubmit('ser')">
            {{ $t("public.Inquire") }}</el-button
          >
        </el-col>
        <el-col :span="1">
          <el-button size="small" type="primary" @click="outreport">{{
            $t("public.Derive")
          }}</el-button>
        </el-col>
      </el-row>
      <el-row :gutter="4" class="elRow" style="display: flex">
        <el-col :span="24">
          <el-button-group>
            <el-button @click="isHidden = 2" type="primary" size="small">{{
              $t("public.New")
            }}</el-button>
            <el-button
              :disabled="deletesBtn"
              :type="typeInfo"
              size="small"
              @click="delMuch(detailsArray)"
              >{{ $t("public.Delete") }}</el-button
            >
          </el-button-group>
        </el-col>
      </el-row>
      <el-row :gutter="4" class="elRow">
        <el-table
          size="mini"
          ref="myTable"
          :data="lists"
          fit
          tooltip-effect="dark"
          class="tableClass"
          stripe
          :row-key="getRowKeys"
          @select-all="selectAll"
          @select="checkChange"
          @selection-change="handleSelectionChange"
          :row-style="iRowStyle"
          :cell-style="iCellStyle"
          :header-row-style="iHeaderRowStyle"
          :header-cell-style="{
            background: '#f7f8fa',
            color: '#606266',
            padding: '0px',
          }"
          :default-sort="{ prop: 'createTime', order: 'descending' }"
          @sort-change="handleSortChange"
        >
          <el-table-column
            type="selection"
            align="center"
            width="60"
            :reserve-selection="true"
          >
          </el-table-column>
          <el-table-column
            width="160"
            :label="$t('SecurityMonitor.DangerIPPage.IPAddress')"
            :show-overflow-tooltip="true"
          >
            <template v-slot="{ row }">
              {{ row.address }}
            </template>
          </el-table-column>
          <el-table-column
            width="160"
            :label="$t('SecurityMonitor.DangerIPPage.IPType')"
            :show-overflow-tooltip="true"
          >
            <template v-slot="{ row }">
              <span v-if="row.type == '0'">{{
                $t("SecurityMonitor.DangerIPPage.custom")
              }}</span>
              <span v-if="row.type == '1'">{{
                $t("SecurityMonitor.DangerIPPage.ExplosionIP")
              }}</span>
              <span v-if="row.type == '2'">{{
                $t("SecurityMonitor.DangerIPPage.ThreatIP")
              }}</span>
              <span v-if="row.type == '3'">{{
                $t("SecurityMonitor.DangerIPPage.IllegalIP")
              }}</span>
            </template>
          </el-table-column>
          <el-table-column
            width="160"
            :label="$t('SecurityMonitor.DangerIPPage.state')"
            :show-overflow-tooltip="true"
          >
            <template v-slot="{ row }">
              <span v-if="row.status == '1'">{{ $t("public.Lock") }}</span>
              <!-- <span v-if="row.status=='0'">锁定</span>
            <span v-if="row.status=='0'">锁定</span>
            <span v-if="row.status=='0'">锁定</span> -->
            </template>
          </el-table-column>
          <!-- sortable="custom" -->
          <el-table-column
            width="160"
            prop="createTime"
            :show-overflow-tooltip="true"
            :label="$t('SecurityMonitor.DangerIPPage.lockTime')"
          >
            <template v-slot="{ row }">
              {{ row.createTime }}
            </template>
          </el-table-column>
          <el-table-column :label="$t('public.Controls')">
            <template v-slot="{ row }">
              <span class="action_icon">
                <!-- style="width: 15px;cursor: pointer; height: 15px; margin-left: 5px" -->
                <img
                  @click="details(row)"
                  :title="$t('public.Delete')"
                  src="@/images/icon_del.png"
                  alt=""
                />
              </span>
            </template>
          </el-table-column>
        </el-table>
        <!-- 分页 -->
        <pagination
          :page="page.currentPage"
          :limit="page.pageSize"
          :total="page.total"
          :pageSizes="[10, 20, 30, 40]"
          v-on:handleChildGetList="handleParentGetList"
        ></pagination>
      </el-row>
    </el-row>
    <dangerIpCom v-if="isHidden == 2" @addDangerIP="addDangerIPs"></dangerIpCom>
  </div>
</template>
<script>
import selectInput from "@/components/selectInput";
import dangerIpCom from "./components/dangerIpCom.vue";
import Eldialog from "@/components/elDialog/index.vue";
import pagination from "../../components/pagination/page";
import { log } from "console";
export default {
  components: { pagination, Eldialog, dangerIpCom, selectInput },
  data() {
    return {
      key: 1, //table  key
      page: {
        total: 0, //总条数
        pageSize: 10, //每页条数,  默认10条
        orderRule: "DESC", //排序规则ASC、DESC
        currentPage: 1,
        orderColume: "createTime", //排序字段createTime
      },
      typeData: [
        {
          value: "",
          label: "IP类型",
        },
        {
          value: "1",
          label: "爆破IP",
        },
        {
          value: "2",
          label: "威胁IP",
        },
        {
          value: "3",
          label: "违规IP",
        },
        {
          value: "0",
          label: "自定义IP",
        },
      ],
      formInline: {
        value: "", //IP类型下拉
        keywords: "", // 设备名称
        optionDate1: "",
        optionDate2: "", // $t('SecurityMonitor.RunInfoPage.dataPlaceholder')
      },
      number: 0,
      lists: [],
      cityarrs: [],
      isHidden: 1, //默认展示列表
      typeInfo: "info", //删除默认置灰
      seleShift: "", // 选中下拉框转译
      deletesBtn: true, //删除默认禁用false
      // placeholder: 'IP类型',
      detailsArray: [], //存放要删除的数据
      pickerOptions1: {},
      pickerOptions0: {},
      outerVisible: false, // 详情弹框
      groupFullNameShowByIndex: "", //用户部门截取的下标
    };
  },
  watch: {},
  mounted() {
    this.groupFullNameShowByIndex = sessionStorage.getItem(
      "groupFullNameShowByIndex"
    );
    this.formInline.optionDate1 = this.$common.getAnyMonth("-", -1);
    this.formInline.optionDate2 = this.$common.getDate();
    this.getDangerIpList();
  },
  // updated() {
  //   if (this.$refs.myTable && this.$refs.myTable.doLayout) {
  //     // myTable是表格的ref属性值
  //     this.$refs.myTable.doLayout()
  //   }
  // },
  methods: {
    selectSearchChange() {},
    //监听排序
    handleSortChange(column) {
      if (column.order === "ascending") {
        this.page.orderRule = "ASC";
      } else if (column.order === "descending") {
        this.page.orderRule = "DESC";
      }
      this.page.orderColume = column.prop;
      this.getDangerIpList();
    },
    getRowKeys(row) {
      return row.id;
    },
    //表格行高
    iRowStyle: function ({ row, rowIndex }) {
      return "height:30px";
    },
    iHeaderRowStyle: function ({ row, rowIndex }) {
      return "height:30px";
    },
    iCellStyle: function ({ row, column, rowIndex, columnIndex }) {
      return "padding:3px 0px";
    },
    //下拉框
    changeSele(item) {
      console.log(item, "item");
      this.formInline.value = item;
      // this.placeholder = item
      // this.typeData.forEach((k) => {
      //   console.log(k,'kkkkkkkkkkk');
      //   if (item == k.value) {
      //     this.placeholder = k.label
      //   }
      // })
      this.seleShift = item;
    },
    // 详情关闭按钮
    handleClose() {
      this.outerVisible = false;
    },
    //新增成功返回
    addDangerIPs(v) {
      if (v == 0) {
        this.$message({
          message: this.$t("public.SuccessfullySaved"),
          type: "success",
          offset: 100,
        });
        this.isHidden = 1;
        this.getDangerIpList();
        this.$refs.myTable.clearSelection();
      }
    },
    handleParentGetList(page, limit) {
      // 响应分页组件查询事件
      this.page.currentPage = page;
      this.page.pageSize = limit;
      // 调用查询方法
      this.onSubmit();
    },
    //时间控件
    changeDate() {
      let start = new Date(this.formInline.optionDate1).getTime();
      let end = new Date(this.formInline.optionDate2).getTime();
      this.pickerOptions0 = {
        disabledDate: (time) => {
          if (end != "") {
            return time.getTime() > Date.now() || time.getTime() > end;
          }
          return time.getTime() > Date.now();
        },
      };
      this.pickerOptions1 = {
        disabledDate: (time) => {
          return (
            time.getTime() < start - 24 * 60 * 60 * 1000 ||
            time.getTime() > Date.now()
          );
        },
      };
    },
    //获取列表
    async getDangerIpList() {
      this.changeDate();
      var startTime = "";
      var endTime = "";
      if (this.formInline.optionDate1) {
        startTime = this.$moment(this.formInline.optionDate1).format(
          "YYYY-MM-DD HH:mm:ss"
        );
      }
      if (this.formInline.optionDate2) {
        endTime = this.$moment(this.formInline.optionDate2).format(
          "YYYY-MM-DD 23:59:59"
        );
      }
      var parmas = {
        currentPage: this.page.currentPage,
        pageSize: this.page.pageSize,
        startTime: this.formInline.optionDate1 ? startTime : "",
        endTime: this.formInline.optionDate2 ? endTime : "",
        type: this.seleShift, //0-自定义IP 1-爆破IP 2-威胁IP 3-违规IP
        address: this.formInline.keywords, //IP地址
      };

      const res = await this.$axios.post(
        "/httpServe/dangerIp/getDataInfo",
        parmas,
        true
      );
      for (var i = 0; i < res.data.content.length; i++) {
        // 对于为空的字段，显示N/A
        var obj2 = res.data.content[i];
        for (var key in obj2) {
          if (obj2[key] == null) {
            obj2[key] = "N/A";
          }
        }
      }
      this.lists = res.data.content;
      this.page.total = res.data.total;
      console.log(this.lists, "this.lists");
    },
    //查询
    async onSubmit() {
      if (this.formInline.keywords.length > 0 && this.page.currentPage > 1) {
        this.page.currentPage = 1;
      }
      var startTime = "";
      var endTime = "";
      if (this.formInline.optionDate1) {
        startTime = this.$moment(this.formInline.optionDate1).format(
          "YYYY-MM-DD HH:mm:ss"
        );
      }
      if (this.formInline.optionDate2) {
        endTime = this.$moment(this.formInline.optionDate2).format(
          "YYYY-MM-DD 23:59:59"
        );
      }
      var parmas = {
        currentPage: this.page.currentPage,
        pageSize: this.page.pageSize,
        startTime: this.formInline.optionDate1 ? startTime : "",
        endTime: this.formInline.optionDate2 ? endTime : "",
        type: this.seleShift, //0-自定义IP 1-爆破IP 2-威胁IP 3-违规IP
        address: this.formInline.keywords, //IP地址
      };
      console.log(parmas);
      const res = await this.$axios.post(
        "/httpServe/dangerIp/getDataInfo",
        parmas,
        true
      );
      for (var i = 0; i < res.data.content.length; i++) {
        // 对于为空的字段，显示N/A
        var obj2 = res.data.content[i];
        for (var key in obj2) {
          if (obj2[key] == null) {
            obj2[key] = "N/A";
          }
        }
      }
      this.lists = res.data.content;
      this.page.total = res.data.total;
      this.page.currentPage = 1;
    },

    async outreport() {
      var startTime = "";
      var endTime = "";
      if (this.formInline.optionDate1) {
        startTime = this.$moment(this.formInline.optionDate1).format(
          "YYYY-MM-DD HH:mm:ss"
        );
      }
      if (this.formInline.optionDate2) {
        endTime = this.$moment(this.formInline.optionDate2).format(
          "YYYY-MM-DD 23:59:59"
        );
      }
      //导出
      let arrOut = [];
      let token2 = sessionStorage.getItem("Authorization")
        ? sessionStorage.getItem("Authorization")
        : "eyJ0eXBlIjoiSldUIiwiYWxnIjoiSFM1MTIifQ.eyJleHAiOjE2MzMzNzU1NjQsInVzZXJJZCI6MSwiaWF0IjoxNjMyNjU1NTY0fQ.OtDA-uF3NfP8xqj902kqxPu54UPgs8SYVY1nBTl1Ifnvoxw_-MPmcN_DKeYDQXHZfEizztITgMABrO0tBmvshA";
      let userString = decodeURIComponent(
        escape(window.atob(token2.split(".")[1]))
      );
      let groupId = JSON.parse(userString).userId;
      this.userId = groupId;
      var parmas = {
        currentPage: this.page.currentPage,
        pageSize: this.page.total,
        startTime: this.formInline.optionDate1 ? startTime : "",
        endTime: this.formInline.optionDate2 ? endTime : "",
        orderColume: this.page.orderColume,
        orderRule: this.page.orderRule,
        type: this.seleShift, //0-自定义IP 1-爆破IP 2-威胁IP 3-违规IP
        address: this.formInline.keywords, //IP地址
      };
      console.log(parmas, "导出parmas");
      const res = await this.$axios.postExport(
        "/httpManageExport/dangerIp/exportExcel",
        parmas,
        true,
        this.userId
      );

      const link = document.createElement("a");
      var fileName = "exportDangerIP_" + Date.parse(new Date()) + ".xls";
      let blob = new Blob([res.data], { type: "application/vnd.ms-excel" });
      link.style.display = "none";
      link.href = URL.createObjectURL(blob);
      link.setAttribute("download", decodeURI(fileName));
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    },

    //复选框监听事件
    handleSelectionChange(obj) {
      this.multipleSelection = obj;
      this.detailsArray = obj;
    },
    // 复选框勾选
    checkChange(selection, row) {
      if (selection.length > 0) {
        this.deletesBtn = false;
        this.typeInfo = "primary";
      } else {
        this.deletesBtn = true;
        this.typeInfo = "info";
      }
    },
    //全选
    selectAll(selection, row) {
      this.checkChange(selection, row);
      this.detailsArray = selection;
    },
    //多选删除
    delMuch(obj) {
      var ids = [];
      obj.forEach((item) => {
        ids.push(item.id);
      });
      var params = { ids: ids };
      this.$confirm(
        this.$t("SecurityMonitor.DangerIPPage.sureDeleteSeleDangerIP"),
        this.$t("public.PromptMessage"),
        {
          confirmButtonText: this.$t("public.Verify"),
          cancelButtonText: this.$t("public.Cancel"),
          type: "warning",
          customClass: "persdsd",
        }
      )
        .then(async () => {
          const res = await this.$axios
            .post("/httpServe/dangerIp/delete", params, true)
            .then(() => {
              this.$message({
                message: this.$t("public.SuccessfullyDeleted"),
                type: "success",
                offset: 100,
              });
              this.getDangerIpList(); //删除成功后调用表格数据
              this.$refs.myTable.clearSelection();
            });
        })
        .catch(() => {
          return false;
        });
    },
    // 删除
    details(info) {
      var params = { ids: [info.id] };
      this.$confirm(
        this.$t("SecurityMonitor.DangerIPPage.sureDeleteSeleDangerIP"),
        this.$t("public.PromptMessage"),
        {
          confirmButtonText: this.$t("public.Verify"),
          cancelButtonText: this.$t("public.Cancel"),
          type: "warning",
          customClass: "persdsd",
        }
      )
        .then(async () => {
          const res = await this.$axios
            .post("/httpServe/dangerIp/delete", params, true)
            .then(() => {
              this.$message({
                message: this.$t("public.SuccessfullyDeleted"),
                type: "success",
                offset: 100,
              });
              this.getDangerIpList(); //删除成功后调用表格数据
              this.$refs.myTable.clearSelection();
            });
        })
        .catch(() => {
          return false;
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.common-wrapper {
  width: 100%;
  height: 100%;
  padding: 10px 10px 0px 10px !important; //页面最外层上右左10
  min-width: 1500px;
  font-family: Microsoft YaHei;
  .elRow {
    margin-bottom: 10px;
  }
}

::v-deep .el-table__row > td {
  border: none;
}
::v-deep .el-table::before {
  height: 0px;
}
::v-deep .el-table th.is-leaf {
  border: none;
}
::v-deep .el-button-group .el-button--primary:first-child {
  border-right-color: #fff !important;
  z-index: 9;
}
</style>
